<template>
	<div class="usercode">
		<div class="usermain">
			<div class="um_head">
				<div class="umh_img">
					<img :src="userCommd.avatar+$root.img_small">
				</div>
				<div class="umh_name">
					<span>{{userCommd.name}}</span>
				</div>
			</div>
			<div class="um_title">
				<span>长按图片识别二维码</span>
			</div>
			<div class="um_desc">
				<span>加入 {{ studioName }}</span>
			</div>
			<div class="um_img">
				<canvas style="width:4.2667rem;height:4.2667rem" id="canvas"></canvas>
			</div>
			<div class="um_txt">
				<span>绑定二维码</span>
			</div>
		</div>
		<div class="userbottom">
			<span>请截图分享给对方</span>
		</div>
	</div>
</template>

<script>


import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import until from '@/api/until.js'
import studio_login_api from '@/api/teaching_studio_no_login.js'
import QRCode from "qrcode"
import tool_api from '@/api/teaching_studio_tool.js'
export default {
	name: 'groupuser',
	data() {
		return {
			userInfo:{},
			userCommd:{},
			studioName:''
		}
	},
	components: {
	

	},
	mounted() {
		document.title="绑定二维码"
		this.userInfo = this.$root.userInfo;
		this.getMemberInfo()
		this.getQrcode();
		this.getStudioInfo()
	},
	methods: {
		
		async getMemberInfo() {
			let user_id = this.$route.query.user_id;
			let app_id = this.$root.app_id
			this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});
			let data = await studio_api.Teaching_studio_apiMemberInfoGet({ user_id, app_id });
			if (data.errcode == 0) {
				this.userCommd=data.other;
				this.$toast.clear();
			}
		},
		//获取二维码
		async getQrcode(){
			let user_id = this.$route.query.user_id;
			let app_id = this.$root.app_id
			let data=await studio_api.Teaching_studio_apiMemberUserQr_codePost({user_id, app_id})
			if(data.errcode==0){
				this.useqrcode(data.other.ticket.url)
			}
		},
		//生成二维码
		useqrcode(url){    
			var canvas=document.getElementById("canvas");    
			console.log(url)
			console.log(this)
		// 调用函数去生成二维码，参数依次为：二维码的容器、要生成的内容、回调函数    
			QRCode.toCanvas(canvas,url,function(error){        
				if(error){
					console.error(error);        
				}else{         
				console.log("success!"); 
				}    
			})
		},
		//获取工作室简介
		async getStudioInfo() {
			let id = this.$root.id;
			let app_id = this.$root.app_id
			this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});
			let data = await studio_login_api.Api_nologinStudio_openInfoGet({ id, app_id });
			if (data.errcode == 0) {
				this.studioName = data.other.name
			}

		},
	}
}
</script>
<style scoped>
.usercode {
	width: 100%;
	height: 100vh;
	display: inline-block;
	background: url("/static/imgs/bg_main.png");
	background-size: 100% 100%;
}
.usermain{
	width: 100%;
	display: inline-block;
}
.um_head{
	width: 100%;
	padding: 0 60px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 60px;
}
.umh_img{
	width: 80px;
	height: 80px;
}
.umh_img img{
	width: 80px;
	height: 80px;
	border-radius: 50%;
}
.umh_name{
	font-size: 36px;
	color: #FFFFFF;
	font-weight: 500;
	margin-left: 30px;
}
.um_title{
	width: 100%;
	margin-top: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 32px;
	color: #FFFFFF;
	line-height: 45px;
}
.um_desc{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: #FFFFFF;
	margin-top: 16px;
	line-height: 33px;
}
.um_img{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	
}
.um_img img{
	width: 320px;
	height: 320px;
}
.um_txt{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	color: #FFFFFF;
	line-height: 40px;
	margin-top: 16px;
}
.userbottom{
	width: 100%;
	height: 60px;
	background: #5d74ff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	color: #FFFFFF;
	position: fixed;
	left: 0;
	bottom: 0;
}
</style>

